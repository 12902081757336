<template>
  <section>
    <div class="content-header">
      <h2>Integrate Universal Design for Learning Principles </h2>
    </div>
    <div class="content-wrapper">
      <p>Universal Design is a concept that originally grew out of architecture and engineering, grounded in the idea that buildings and spaces should be design with the diverse needs of the end user in mind. Universal Design for Learning (UDL) applies this same principle to teaching&#8212;indicating that we should design our curriculum and pedagogical approaches with the diverse needs of our learners in mind. </p>
      <p>Universal design is grounded in the neuropsychological science of learning, and integrating UDL approaches is beneficial to the learning of all students. UDL approaches particularly benefit students with disability-related barriers to learning. It promotes inclusive learning for students with disabilities by reducing students’ need to go through often onerous and time-consuming accommodation processes to access their learning. </p>
      <p>UDL approaches are grounded in three core guiding principles:</p>
      <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
          :wheelControl="false"
        >
          <slide>
            <div class="slide">
              <p>1. Provide multiple means of engagement: Students have the opportunity to connect course content to their values, experiences, and goals, and can integrate with course material within a collaborative, caring and engaged class environment that motivates participation.</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>2. Provide multiple means of representation: Students can access learning materials through multiple modalities such as audio, visual, textual and other forms of media.</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>3. Provide multiple means of action & expression: Students can express and demonstrate their learning through multiple modalities such as audio, visual, textual and other forms of media.</p>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
      <p>Across all guiding principles of UDL is an overarching and intentional effort to maximize student choice and autonomy to access information in diverse ways, engage with their peers and instructors, and express their learning for assessment. This choice enables them to align their learning with their unique strengths and abilities.</p>
      <p>Learn more through the rich UDL Framework developed by the Centre for Applied Special Technology, who created the <a
          href="https://www.cast.org/impact/universal-design-for-learning-udl"
          target="_blank"
        >UDL Framework</a> and <a
          href="https://udlguidelines.cast.org/"
          target="_blank"
        >UDL Guidelines</a> that have enhanced learning effectiveness and accessibility.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
